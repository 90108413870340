import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faHeadset } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import io from "socket.io-client";

import "./ChatBox.css";

const server_url = "https://webfly.io";
//const server_url = "https://triviarat.net";
//const server_url = "http://localhost:4000";

const ChatBox = () => {
	const [isChatVisible, setChatVisibility] = useState(false);
	const [messages, setMessages] = useState([]);
	const messagesEndRef = useRef(null);
	const [showStartMessage, setShowStartMessage] = useState(false);
	const [locationData, setLocationData] = useState({});
	const location = useLocation();

	const [currentChannel, setCurrentChannel] = useState(
		sessionStorage.getItem("current_channel")
	);

	let current_user = sessionStorage.getItem("current_user");

	if (!current_user) {
		current_user = "user_" + Math.random().toString(36).substr(2, 9);
		sessionStorage.setItem("current_user", current_user);
	}

	//let current_channel = sessionStorage.getItem("current_channel");

	//console.log("Current Chat:", current_user);

	//console.log("socket: ", server_url);

	useEffect(async () => {
		if (isChatVisible) {
			const timer = setTimeout(() => {
				setShowStartMessage(true);
			}, 3000);

			const openChat = await socket.emit("open_chat_box", {
				user_id: current_user,
			});
			console.log("openChat", openChat);
			return () => clearTimeout(timer);
		}
	}, [isChatVisible]);

	useEffect(() => {
		const getUserCountry = async () => {
			try {
				const response = await fetch("https://ipapi.co/json/");
				const data = await response.json();
				setLocationData(data);

				console.log("Data", data.country_name);
			} catch (error) {
				console.log("Error fetching user country:", error);
			}
		};

		getUserCountry();
	}, [location.search]);

	useEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [messages]);

	const [socket, setSocket] = useState(null);
	const [inputValue, setInputValue] = useState("");

	//const user = new Date().getTime().toString();
	const [user, setUser] = useState(current_user);

	useEffect(() => {
		const newSocket = io(server_url); //io("https://triviarat.net:4000");
		setSocket(newSocket);

		newSocket.on("connect", (data) => {
			console.log("onConnect client: ", data);
			console.log("newSocket Connected to server");
		});

		newSocket.on("disconnect", (reason) => {
			console.log("newSocket Disconnected:", reason);
		});

		newSocket.on("connect_error", (error) => {
			console.error("newSocket Connection error:", error);
		});

		newSocket.on("get_channel", (channelId) => {
			console.log("Getting Channel...", channelId);
			// if (data.userId != current_user) {
			// 	return;
			// }

			if (channelId) {
				sessionStorage.setItem("current_channel", channelId);
				console.log("...Setting chanel to", channelId);
				setCurrentChannel(channelId);
			} else {
				console.log("data.channel is null");
			}
		});

		newSocket.on("slack_message", (data) => {
			const localChannel = sessionStorage.getItem("current_channel");

			console.log("Slack-message", data.channel, localChannel);

			if (data.channel !== localChannel) {
				return;
			}

			setMessages((prevMessages) => [
				...prevMessages,
				{ user: "admin", message: data.text },
			]);
		});

		return () => {
			newSocket.disconnect();
		};
	}, []);

	const handleKeyDown = async (event) => {
		if (event.key === "Enter" && inputValue.trim()) {
			await socket.emit("message", { text: inputValue, user_id: current_user });
			//setMessages([...messages, { user: "user", message: inputValue }]);
			setMessages((prevMessages) => [
				...prevMessages,
				{ user: "user", message: inputValue },
			]);

			setInputValue(""); // Clear the input
		}
	};

	// Separate styles into a style object
	const styles = {
		button: {
			position: "fixed",
			bottom: "10px",
			left: "10px",
			zIndex: "1000",
			fontSize: "16px",
			padding: "12px",
		},

		small: {
			fontSize: "8px",
			marginTop: "2px",
		},

		admin: {
			backgroundColor: "#add8e6",
			fontSize: "12px",
			padding: "10px",
			borderRadius: "5px",
		},
		user: {
			backgroundColor: "#eee",
			fontSize: "12px",
			padding: "10px",
			borderRadius: "5px",
		},
	};

	return (
		<>
			<button
				className="btn btn-type-6 btn-sm"
				style={styles.button}
				onClick={() => setChatVisibility(!isChatVisible)}
			>
				Chat <FontAwesomeIcon icon={faComment} />
				<div style={styles.small}>With a Real Human</div>
			</button>

			{isChatVisible && (
				<div className="chatWindow">
					<div className="chatHistory">
						<div className="chatMessageBox">
							<div className="w-chat">
								<a href="https://webfly.io" target="_blank">
									wCHAT
								</a>
							</div>
							<div className="alert" style={styles.admin}>
								{showStartMessage ? (
									<>
										<FontAwesomeIcon
											icon={faHeadset}
											pull="left"
											className="pt-1"
										/>
										What can I help you with?
									</>
								) : (
									<i>Connecting....</i>
								)}
							</div>

							{messages.map((message, index) => (
								<div
									className="alert- boxItems"
									key={`${message.message}-${index}`}
								>
									<span style={styles[message.user]}>
										{message.user === "admin" && (
											<FontAwesomeIcon
												icon={faHeadset}
												pull="left"
												className="pt-1"
											/>
										)}
										{message.message}
									</span>
								</div>
							))}
							<div ref={messagesEndRef} />
						</div>
					</div>
					<div style={styles.input}>
						<input
							type="text"
							className="form-control"
							value={inputValue}
							onChange={(e) => setInputValue(e.target.value)}
							onKeyDown={handleKeyDown}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default ChatBox;
