import React, { useEffect } from "react";
import blogimg4 from "../img/blog/blog-4.jpg";
import blogimg5 from "../img/blog/blog-5.jpg";
import blogimg6 from "../img/blog/blog-6.jpg";
import wdg1 from "../img/wdg/wdg-img-1.jpg";
import wdg2 from "../img/wdg/wdg-img-2.jpg";
import wdg3 from "../img/wdg/wdg-img-3.jpg";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

const BlogList = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		body: "",

		city: "",
		state: "",
		country: "",
	});

	const [formMessage, setFormMessage] = useState("");
	const [formSubmitted, setFormSubmitted] = useState(false);

	let { slug } = useParams();
	//set form position to the slug

	useEffect(() => {
		setFormData({ ...formData, position: slug });
	}, [slug]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const jobs = {
		"web-developer": {
			name: "Web Developer",
			description:
				"We are looking for a web developer to join our team.  You will be responsible for building and maintaining our website, as well as our trivia platform built on the MERN stack. Bonus points for DevOps experience",
		},
		"trivia-host": {
			name: "Trivia Host",
			description:
				"Although we don't hire hosts directly, we take applications and regularly pass them along to our hosts.  If you are interested in hosting trivia, please fill out the form below and we will pass your information along to our hosts in the appropriate region.  They will contact you directly to arrange an interview.",
		},
		sales: {
			name: "Sales",
			description:
				"We are currently seeking sales-reps to work on commission and sell our new automated 'LIVE' feature",
		},
	};

	const currentJob = jobs[slug];

	const handleSubmit = async (event) => {
		event.preventDefault();
		//env REACT_APP_BE_URL

		const url = process.env.REACT_APP_BE_URL + "/public/careers";
		//add location as object
		const formDataWithLocation = { ...formData };
		formDataWithLocation.location = {
			city: formData.city,
			state: formData.state,
			country: formData.country,
		};

		try {
			const response = await axios.post(url, formDataWithLocation);
			setFormSubmitted(true);
			setFormMessage("Application submitted!");
			console.log("Response:", response.data);
		} catch (error) {
			setFormMessage("Failed to send email. Please try again later.");
			console.error("There was an error sending the email", error);
		}
	};

	return (
		<>
			<div
				id="apork-latest-blog-area"
				className="apork-latest-blog-area mt-3 mb-110"
			>
				<div className="container">
					<div className="my-4">
						<Link to="/careers" className="btn btn-primary btn-sm">
							{"<"} Back to Careers
						</Link>
					</div>

					<h3>{currentJob.name}</h3>
					<p className="text-muted">{currentJob.description}</p>

					<div className="my-3">
						{formSubmitted ? (
							<div className="success-message text-center d-flex justify-content-center p-2 m-auto">
								<h3>
									Application Recieved. We'll be in touch asap. <br /> Thanks,
									pal.
								</h3>
							</div>
						) : (
							<form className="apork-contact-form" onSubmit={handleSubmit}>
								<div className="container">
									<div className="row">
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<input
												className="form-control"
												type="text"
												name="name"
												placeholder="Name"
												value={formData.name}
												onChange={handleChange}
											/>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<input
												className="form-control"
												type="email"
												name="email"
												placeholder="E-mail *"
												value={formData.email}
												onChange={handleChange}
											/>
										</div>

										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<input
												className="form-control"
												type="text"
												name="country"
												placeholder="Country *"
												value={formData.country}
												onChange={handleChange}
											/>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<input
												className="form-control"
												type="text"
												name="state"
												placeholder="State/Province *"
												value={formData.state}
												onChange={handleChange}
											/>
										</div>
										<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
											<input
												className="form-control"
												type="text"
												name="city"
												placeholder="City (optinal)"
												value={formData.city}
												onChange={handleChange}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-xl-12">
											<textarea
												className="form-control"
												name="body"
												cols="30"
												rows="8"
												value={formData.body}
												onChange={handleChange}
												placeholder="Describe your experience and why you would be good for the job!"
											></textarea>
											<button type="submit" className="btn btn-type-6">
												submit
											</button>
										</div>
									</div>
									<p className="form-message float-left mt-15"></p>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default BlogList;
