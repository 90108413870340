// copy.js
import store from "../store"; // Import the store module

const copyData = [
	{
		keywords: ["team", "collaboration"],
		about_header: "Try our Team-building quizzes.",
		about_desc:
			"How well do you know your team?  What process or tool at your company needs to be abolished asap?  If you were all stranded on an island, who would be hunting wild boar? Find out anonymously with our team-building quizzes.",
		cta: "Start Playing!",
	},
	{
		keywords: ["friends", "family"],
		about_header:
			"Find out who's the smartest in your group of friends.  Or try our 'Trivia with Friends' category to find out what you really think of eachother.  Warning: friendships may end or strengthen after playing .",
		cta_heading: "Learn More",
	},
	{
		keywords: ["groups"],
		about_header: "Perfect for large groups",
		about_desc: `Host a game of trivia with dozens, hundreds, or even thousands of players. Simply hand out your link/QR code, ask questions, use our smart-marking wizard and watch as the scoreboard automatically tallies the points.`,
	},
	{
		keywords: ["hosting", "app", "software"],
		about_header: "Hosting trivia should be simple.",
		about_desc: `Instruct your players to navigate to
		TriviaRat.com/yourcode where they can enter
		their team name. It's all downhill from there: just start
		announcing your questions and watch as the responses appear in
		real-time.`,
		cta: "Start Hosting!",
	},
];

function getCopy() {
	// Retrieve the keyword from the store
	const kw = store.getItem("kw");
	const keywords = kw ? kw.split(" ") : []; // Assuming keywords are space-separated

	// Find the first object where any of its keywords is in the keyword set
	const match = copyData.find((item) =>
		item.keywords.some((keyword) => keywords.includes(keyword))
	);

	// Return the found object or the default object (assumed to be the last one)
	return match || copyData[copyData.length - 1];
}

export default getCopy;
