import React from "react";
import ReactDOM from "react-dom";
import "font-awesome/css/font-awesome.min.css";
import App from "./App";
import WhiteApp from "./WhiteApp";

import reportWebVitals from "./reportWebVitals";

const path = window.location.href;

ReactDOM.render(
	<React.StrictMode>
		{path.includes("play.") ? <WhiteApp /> : <App />}
	</React.StrictMode>,
	document.getElementById("root")
);

reportWebVitals();
