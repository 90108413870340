import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import getReviews from "../services/testimonials";

import testibg from "../img/testimonial/testimonial-bg.png";
const Testimonial = () => {
	//const reviewsObject = getReviews();
	const reviews = getReviews();
	console.log("reviews", reviews);

	return (
		<>
			<div
				id="apork-testimonial-area"
				className="apork-testimonial-area pt-110 pb-110 mt-110 mb-110 wow fadeInUp"
				style={{ background: `url('${testibg}') no-repeat center center` }}
			>
				<div className="container">
					<div className="row">
						<div className="col-xl-8 col-lg-10 mx-auto col-md-12">
							<div className="testimonial-wraper text-center">
								<OwlCarousel
									className="owl-theme owl-carousel"
									loop
									items={1}
									margin={10}
									autoplay={100}
									slideSpeed={100}
								>
									{reviews.map(function (d, idx) {
										return (
											<div className="single-testimonial" key={idx}>
												<p>"{d.text}"</p>
												<div className="title-desig">
													<h4>
														{d.name} {d.type ? <span>{d.type}</span> : null}
													</h4>
													{d.url ? (
														<a
															href={d.url}
															className="btn"
															data-style={{
																color: "white",
																textDecorationLine: "underline",
															}}
														>
															{d.url_title}
														</a>
													) : null}
												</div>
												{/*<img src={climg1} alt="clientimg" />*/}
											</div>
										);
									})}
									{/*
                  <div className="single-testimonial">
                    <p>
                      is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem
                    </p>
                    <div className="title-desig">
                      <h4>
                        Wells mark <span>designer</span>
                      </h4>
                    </div>
                  </div>
									*/}
								</OwlCarousel>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Testimonial;
