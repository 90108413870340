/*
 * Keys used:
 * kw //keyword
 * utm_source
 *
 */

const store = {
	setItem(key, value) {
		try {
			localStorage.setItem(key, JSON.stringify(value));
		} catch (e) {
			console.error("Error saving to localStorage", e);
		}
	},
	getItem(key) {
		try {
			const item = localStorage.getItem(key);
			return item ? JSON.parse(item) : null;
		} catch (e) {
			console.error("Error getting data from localStorage", e);
			return null;
		}
	},
	removeItem(key) {
		try {
			localStorage.removeItem(key);
		} catch (e) {
			console.error("Error removing item from localStorage", e);
		}
	},
};

export default store;
