import React, { useEffect } from "react";
import Hero from "../whitecomponents/Hero";
import Scrolltop from "../components/Scrolltop";
import ReactGA from "react-ga";
import { useState } from "react";
import store from "../store";
// UA-196224044-1
const queryString = require("query-string");

const Home = () => {
	const currentUrl = window.location.href;
	const [url, updateUrl] = React.useState(currentUrl + "sign-in");

	const setUtmSource = (utmSource) => {
		store.setItem("utm_source", utmSource);
	};

	useEffect(() => {
		var parsed = queryString.parse(window.location.search);
		if (typeof parsed.utm_source !== "undefined") {
			var address = "/sign-up?utm_source=" + parsed.utm_source;
			updateUrl(address);
		}

		//get subdomain:
		//console.log("REACT_APP_ENV", process.env.REACT_APP_ENV);
		try {
			const subdomain = window.location.hostname.split("."); //[0];
			console.log("Got subdomain", subdomain[1]);
			if (subdomain && subdomain[0] === "play") {
				setUtmSource("wl-play-" + subdomain[1]);
			}
		} catch (err) {
			console.log("utm-subdomain-error", err);
		}
	}, []);

	return (
		<>
			{/* <Header /> */}
			<main>
				<Hero url={url} />
				{/* <About url={url} />
				<Features />

				<Testimonial />
				<Video />
				<Cta url={url} />
				<Accordion />
				<Pricing url={url} />
				<Footer /> */}
			</main>
			<Scrolltop />
		</>
	);
};

export default Home;
