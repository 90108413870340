import React, { useEffect } from "react";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import CareersContent from "../components/CareersContent";
import CareersRecord from "../components/CareersRecord";
import { useParams } from "react-router-dom";

const Contact = () => {
	let { slug } = useParams();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Header />
			<main>
				<Breadcrumb name="Careers" />

				{slug ? <CareersRecord /> : <CareersContent />}

				<Footer />
			</main>
			<Scrolltop />
		</>
	);
};

export default Contact;
