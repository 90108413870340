import React from "react";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import QuestionsContent from "../components/QuestionsContent";

const Contact = () => {
	return (
		<>
			<Header />
			<main>
				{/* <Breadcrumb name="questions" /> */}

				<QuestionsContent />

				<Footer />
			</main>
			<Scrolltop />
		</>
	);
};

export default Contact;
