import React from "react";
import aboutimg1 from "../img/about/school.png";
import aboutimg2 from "../img/about/about-icon-1.png";
const Education = () => {
	return (
		<>
			<div id="apork-about-area" className="apork-about-area mt-110 mb-110">
				<div className="container">
					<div className="row">
						<div className="col-xl-6 col-lg-6 wow fadeInLeft">
							<div className="about-img">
								<img
									src={aboutimg1}
									alt="Use trivia rat in the class room!"
									className="school-img"
								/>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 wow fadeInRight">
							<div className="about-content mt-110 mb-90">
								<img src={aboutimg2} alt="img" />
								<h3>
									Need quizzing tools for <span>Education?</span>
								</h3>
								<p>
									Welcome to the future of education! Our Education Portal is
									specifically designed to transform traditional classrooms into
									dynamic learning environments. Here, education meets
									innovation, allowing teachers to host real-time, interactive
									quizzes that captivate students and elevate the learning
									experience.
								</p>
								<div>
									<a
										href="https://edu.triviarat.com?utm_source=hp"
										className="btn btn-type-2"
										data-animation="fadeInUp"
										data-delay=".6s"
									>
										Try it out!
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Education;
