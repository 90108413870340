import React from "react";
import blogimg4 from "../img/blog/blog-4.jpg";
import blogimg5 from "../img/blog/blog-5.jpg";
import blogimg6 from "../img/blog/blog-6.jpg";
import wdg1 from "../img/wdg/wdg-img-1.jpg";
import wdg2 from "../img/wdg/wdg-img-2.jpg";
import wdg3 from "../img/wdg/wdg-img-3.jpg";
import { Link } from "react-router-dom";
const BlogList = () => {
	const jobs = [
		{
			name: "Web Developer",
			slug: "web-developer",
		},
		{
			name: "Trivia Host",
			slug: "trivia-host",
		},
		{
			name: "Sales",
			slug: "sales",
		},
	];
	return (
		<>
			<div
				id="apork-latest-blog-area"
				className="apork-latest-blog-area mt-110 mb-110"
			>
				<div className="container">
					<div className="row">
						<div className="col-xl-8 col-lg-8 col-md-7 col-sm-12">
							<h3 className="mb-4">Positions</h3>
							<div className="single-blog-wraper mb-30 wow fadeInUp">
								{jobs.map((job) => (
									<div className="blog-content-wrap">
										<Link
											to={`/careers/${job.slug}`}
											className="btn  btn-type-2 btn-sm text-white mb-2"
										>
											{job.name}
										</Link>
									</div>
								))}
							</div>

							<div className="apork-pagination mt-30">
								<nav className="pagination">
									<div className="nav-links">
										<span aria-current="page" className="page-numbers current">
											1
										</span>
									</div>
								</nav>
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 wow fadeInUp">
							<div className="single-sid-wdg sm-mt-30">
								<form action="#" className="wdg-search-form">
									<input type="text" placeholder="type to search here..." />
									<button className="submit-btn" type="submit">
										<i className="fa fa-search"></i>
									</button>
								</form>
							</div>
							<div className="single-sid-wdg">
								<h4 className="sid-wdg-title">Job Archive</h4>
								<ul className="wdg-post-archive">
									<li>
										<Link to="#">
											jun 2023<span>(11)</span>
										</Link>
									</li>
									<li>
										<Link to="#">
											aug 2023<span>(02)</span>
										</Link>
									</li>
									<li>
										<Link to="#">
											sep 2023<span>(13)</span>
										</Link>
									</li>
								</ul>
							</div>
							<div className="single-sid-wdg">
								<h4 className="sid-wdg-title">Latest Jobs</h4>
							</div>

							<div className="single-sid-wdg">
								<h4 className="sid-wdg-title">Job Tags</h4>
								<ul className="list-inline tag-list">
									<li>
										<Link to="#">tech</Link>
									</li>
									<li>
										<Link to="#">sales</Link>
									</li>
									<li>
										<Link to="#">ui</Link>
									</li>
									<li>
										<Link to="#">dev</Link>
									</li>
									<li>
										<Link to="#">support</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BlogList;
