import React, { useEffect } from "react";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import CareersContent from "../components/CareersContent";
import CareersRecord from "../components/CareersRecord";
import { useParams } from "react-router-dom";

import "./page.css";

const Contact = () => {
	let { slug } = useParams();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Header />
			<main>
				<Breadcrumb name="Privacy Policy" />

				<div class="box">
					<div id="maincontent">
						<div className="page-container">
							<p>Last Updated: May 4, 2023</p>
							<p>
								Triviarat ("we," "us," or "our") is committed to protecting the
								privacy of our users. This Privacy Policy outlines how we
								collect, use, and protect your information when you use our
								trivia hosting platform, available at triviarat.com (the
								"Platform").
							</p>
							<p>
								By using the Platform, you agree to the collection and use of
								your information in accordance with this Privacy Policy.
							</p>
							<h4>1. Information We Collect</h4>
							<p>
								When you use the Platform, we collect the following types of
								information:
							</p>
							<ul>
								<li>
									Personal Information: We collect your name and email address
									when you sign up for an account or participate in our trivia
									games.
								</li>
								<li>
									Game Information: We collect information about the trivia
									games you participate in, such as your responses, scores, and
									game settings.
								</li>
								<li>
									Cookies: We use cookies to remember your login information and
									maintain your session when you use the Platform on your mobile
									device.
								</li>
							</ul>
							<h4>2. How We Use Your Information</h4>
							<p>We use your information for the following purposes:</p>
							<ul>
								<li>
									To provide and maintain the Platform and our services,
									including processing your responses and scores.
								</li>
								<li>
									To improve and enhance the Platform by analyzing game
									information, particularly game settings, to optimize gameplay
									and user experience.
								</li>
								<li>
									To communicate with you about your account or any updates
									related to the Platform.
								</li>
							</ul>
							<h4>3. Sharing Your Information</h4>
							<p>
								We do not share your personal information with third parties.
							</p>
							<h4>4. Data Security</h4>
							<p>
								We take the security of your information seriously. We implement
								encryption and authentication measures to protect your data from
								unauthorized access, alteration, disclosure, or destruction.
							</p>
							<h4>5. Accessing, Modifying, and Deleting Your Information</h4>
							<p>
								You can access, modify, or delete your personal information by
								logging into your account on the Platform. If you need further
								assistance, please contact us at contact@triviarat.com.
							</p>
							<h4>6. Changes to This Privacy Policy</h4>
							<p>
								We may update our Privacy Policy from time to time. We will
								notify you of any changes by posting the new Privacy Policy on
								this page and updating the "Last Updated" date at the top of
								this Privacy Policy. You are advised to review this Privacy
								Policy periodically for any changes.
							</p>
							<h4>Contact Us</h4>
							<div></div>
							If you have any questions, concerns, or requests related to this
							Privacy Policy or our privacy practices, please contact us at:
							contact@triviarat.com
							<p>
								By using the Platform, you acknowledge that you have read and
								understand this Privacy Policy and agree to its terms.
							</p>
						</div>
					</div>
				</div>

				<Footer />
			</main>
			<Scrolltop />
		</>
	);
};

export default Contact;
