import React from "react";
import acimg from "../img/faqs/faqs-1.png";
//import ratsimg from "../img/general/rats-hosting.jpg";
import ratsimg from "../img/general/live-trivia.png";
import { Link } from "react-router-dom";
const Accordion = () => {
	return (
		<>
			<div id="aprok-faq-area" className="aprok-faq-area pt-4">
				<div className="container">
					<div className="row">
						<div className="col-xl-8 col-lg-8 mx-auto">
							<div className="section-title text-center mb-80">
								<h2>FAQ</h2>
								<p>
									If these FAQs don't answer your questions. Feel free to{" "}
									<a href="https://triviarat.com/contact-us">Contact Us</a>{" "}
									Directly.
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-12 wow fadeInLeft">
							<div className="faq-img">
								<img src={ratsimg} alt="TriviaRat hosts having a good time!" />
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-12 wow fadeInRight">
							<div className="aprok-accordion-wrapper">
								<div className="accordion" id="accordionExample">
									<div className="card">
										<div className="card-header" id="headingOne">
											<h2 className="mb-0">
												<Link
													className="btn btn-link"
													to="#"
													data-toggle="collapse"
													data-target="#collapseOne"
													aria-expanded="true"
													aria-controls="collapseOne"
												>
													Is it really free?
												</Link>
											</h2>
										</div>

										<div
											id="collapseOne"
											className="collapse show"
											aria-labelledby="headingOne"
											data-parent="#accordionExample"
										>
											<div className="card-body">
												<p>
													It's free for up to 5 teams. For more features and{" "}
													<i>unlimited </i> teams, it's just $19 per month.
													Cancel anytime.
												</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="headingTwo">
											<h2 className="mb-0">
												<Link
													className="btn btn-link collapsed"
													to="#"
													data-toggle="collapse"
													data-target="#collapseTwo"
													aria-expanded="false"
													aria-controls="collapseTwo"
												>
													Is there a mobile app?
												</Link>
											</h2>
										</div>
										<div
											id="collapseTwo"
											className="collapse"
											aria-labelledby="headingTwo"
											data-parent="#accordionExample"
										>
											<div className="card-body">
												<p>
													No. One of the things people love about TriviaRat is
													that there is, in fact, no app. You can play on any
													device with a web browser. No downloads, no updates,
													no player authentication.
												</p>
											</div>
										</div>
									</div>
									<div className="card">
										<div className="card-header" id="headingThree">
											<h2 className="mb-0">
												<Link
													className="btn btn-link collapsed"
													to="#"
													data-toggle="collapse"
													data-target="#collapseThree"
													aria-expanded="false"
													aria-controls="collapseThree"
												>
													How does it work?
												</Link>
											</h2>
										</div>
										<div
											id="collapseThree"
											className="collapse"
											aria-labelledby="headingThree"
											data-parent="#accordionExample"
										>
											<div className="card-body">
												<p>
													As a host, you will provide your room code or link
													(triviarat.com/john) to the players. Once the players
													arrive to your room, they can create a team name and
													are ready to go! On the host side, you will simply
													switch between 3 game states:{" "}
													<strong>Idle, Game On and Marking.</strong>
												</p>
											</div>
										</div>
									</div>

									<div className="card">
										<div className="card-header" id="headingfour">
											<h2 className="mb-0">
												<Link
													className="btn btn-link collapsed"
													to="#"
													data-toggle="collapse"
													data-target="#headingFour"
													aria-expanded="false"
													aria-controls="headingFour"
												>
													What about the Questions?
												</Link>
											</h2>
										</div>
										<div
											id="headingFour"
											className="collapse"
											aria-labelledby="headingfour"
											data-parent="#accordionExample"
										>
											<div className="card-body">
												<p>
													Many hosts use TriviaRat with their own questions.
													Which can be kept elsewhere, or pre-entered into the
													TriviRat. We also offer access to our growing bank of
													thousands of questions across various categories.
												</p>
											</div>
										</div>
									</div>

									<div className="card">
										<div className="card-header" id="headingFive">
											<h2 className="mb-0">
												<Link
													className="btn btn-link collapsed"
													to="#"
													data-toggle="collapse"
													data-target="#headingFive"
													aria-expanded="false"
													aria-controls="headingFive"
												>
													As a venue, where can I find a host?
												</Link>
											</h2>
										</div>
										<div
											id="headingFive"
											className="collapse"
											aria-labelledby="headingFive"
											data-parent="#accordionExample"
										>
											<div className="card-body">
												<p>
													With our ever-growing roster of hosts across the
													globe. We are sure to have a host near you. Please
													visit our{" "}
													<a href="https://triviarat.com/venue-sign-up">
														Venue Sign Up
													</a>{" "}
													page to get connected with the right host for you.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Accordion;
