import React, { useEffect } from "react";
import Header from "../components/Header";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import CareersContent from "../components/CareersContent";
import CareersRecord from "../components/CareersRecord";
import { useParams } from "react-router-dom";

import "./page.css";

const Contact = () => {
	let { slug } = useParams();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Header />
			<main>
				<Breadcrumb name="How to host Trivia" />

				<div class="box">
					<div id="maincontent">
						<div className="main-container">
							<p>What do you need to host a game of trivia?</p>
							<h4>Ingredients</h4>
							<p>
								<ul>
									<li>
										1. A group of players (a bar crowd, friends or family, etc)
									</li>
									<li>2. A host (you?)</li>
									<li>
										3. Trivia Questions (you can find some{" "}
										<a href="https://triviarat.com/random"> here</a>)
									</li>
									<li>
										4. A way for the players to answer their questions. You can
										hand out a piece of paper and a pen, or you have them answer
										on their smartphones with a{" "}
										<a href="https://triviarat.com/">digital platform</a>
									</li>
								</ul>
							</p>
							<h4>Instructions</h4>

							<ul>
								<li>
									1. Gather your players and have them sit in a circle or at a
									table.
								</li>
								<li>2. Explain the rules of the game.</li>
								<li>
									3. Start asking questions. You can ask them one at a time or
									in rounds.
								</li>
								<li>
									4. Keep track of the scores. You can do this on a piece of
									paper or with a digital platform.
								</li>
								<li>
									5. At the end of the game, tally up the scores and declare a
									winner.
								</li>
							</ul>
						</div>
					</div>
				</div>

				<Footer />
			</main>
			<Scrolltop />
		</>
	);
};

export default Contact;
