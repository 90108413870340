import React, { useState, useEffect } from "react";
import Map from "./Map";
import axios from "axios";
import "./Questions.css";

const QuestionsContent = () => {
	const [question, setQuestion] = useState({});

	const getQuestions = async () => {
		const url = "https://api.triviarat.com/api/get/random";
		const response = await axios.get(url);
		let q = response.data.results;
		setQuestion({});

		setTimeout(() => {
			setQuestion(q);
		}, 4);

		//setQuestion(q);
	};

	//getQuestion on load useeffect
	useEffect(() => {
		getQuestions();
	}, []);

	//getQuestions();
	//https://triviarat.com/api/auth/swap-question/

	return (
		<>
			<div id="apork-contact-area" className="apork-contact-area mt-4 mb-110">
				<div
					id="apork-contact-form-area"
					className="apork-contact-form-area wow fadeInUp"
				>
					<div className="containe text-center">
						<h4>Random Question Generator</h4>
						<p className="text-muted">
							Get a sense of the questions availble in{" "}
							<a href="https://triviarat.com/sign-up?utm_source=random-questions">
								TriviaRat
							</a>
							's <i>Library Mode</i>
						</p>

						<div className="mt-40 d-flex text-center justify-content-center align-center flex-column questionDiv">
							{Object.keys(question).length && (
								<div className="questionBox">
									<h1 className="question wow fadeInUp">{question.question}</h1>
									<h4 className="text-faded mt-4 wow fadeInUp slow">
										{question.answer}
									</h4>
								</div>
							)}
							<div className="mt-5 pt-4">
								<button
									className="btn btn-primary btn-type-5"
									onClick={() => getQuestions()}
								>
									New Random Question
								</button>
							</div>

							<div className="mt-4 pt-4">
								<p className="text-muted text-sm">
									Allow players to signup via a quick QR, <br />
									submit their answers and see who wins! <br />
									Sign-up below. Or not. You do you.
								</p>
								<button
									className="btn"
									onClick={() => {
										window.location.href =
											"https://triviarat.com/sign-up?utm_source=random-question";
									}}
								>
									Sign-up
								</button>
							</div>
							<div className="my-2">
								<button
									className="btn btn-link"
									onClick={() => {
										window.location.href = "https://triviarat.com/sign-up";
									}}
								>
									{"< "}
									Back
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default QuestionsContent;
