import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Map extends Component {
	static defaultProps = {
		center: {
			lat: 43.245091,
			lng: -79.878249,
		},
		zoom: 11,
	};

	render() {
		return (
			<div className="mt-80 mb-80" id="apork-map">
				<GoogleMapReact
					bootstrapURLKeys={{
						key: "AIzaSyCEaXJpwOZj983A15v17kMSajVi3t0TWeI",
					}}
					defaultCenter={this.props.center}
					defaultZoom={this.props.zoom}
				>
					<AnyReactComponent
						lat={59.955413}
						lng={30.337844}
						text="Home of the Rats"
					/>
				</GoogleMapReact>
			</div>
		);
	}
}

export default Map;
