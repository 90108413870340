import React, { useState } from "react";
import Map from "./Map";
import axios from "axios";

const ContactContent = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		body: "",
		channel: "C04CDBJ9ANP",
		service: "TriviaRat",
	});

	const [formMessage, setFormMessage] = useState("");
	const [formSubmitted, setFormSubmitted] = useState(false);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			const big = "goochytobi2023";
			let url = "https://webfly.io/sendmail/send?slack=true&key=" + big;

			const response = await axios.post(url, formData);
			setFormSubmitted(true);

			setFormMessage("Email sent successfully!");
			console.log("Response:", response.data);
		} catch (error) {
			setFormMessage("Failed to send email. Please try again later.");
			console.error("There was an error sending the email", error);
		}
	};

	return (
		<>
			<div id="apork-contact-area" className="apork-contact-area mt-110 mb-110">
				<div
					id="apork-contact-form-area"
					className="apork-contact-form-area wow fadeInUp"
				>
					{formSubmitted ? (
						<div className="success-message text-center d-flex justify-content-center p-2 m-auto">
							<h3>
								Message Recieved. We'll be in touch asap. <br /> Thanks, pal.
							</h3>
						</div>
					) : (
						<form className="apork-contact-form" onSubmit={handleSubmit}>
							<div className="container">
								<div className="row">
									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
										<input
											className="form-control"
											type="text"
											name="name"
											placeholder="Name"
											value={formData.name}
											onChange={handleChange}
										/>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
										<input
											className="form-control"
											type="email"
											name="email"
											placeholder="E-mail *"
											value={formData.email}
											onChange={handleChange}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-xl-12">
										<textarea
											className="form-control"
											name="body"
											cols="30"
											rows="8"
											value={formData.body}
											onChange={handleChange}
											placeholder="Comments.."
										></textarea>
										<button type="submit" className="btn btn-type-6">
											submit
										</button>
									</div>
								</div>
								<p className="form-message float-left mt-15"></p>
							</div>
						</form>
					)}
				</div>

				<div id="contact-map-area" className="contact-map-area wow fadeInUp">
					<div className="container">
						<div className="row">
							<div className="col-xl-12">
								<Map />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ContactContent;
