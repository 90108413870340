import React, { useEffect } from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Education from "../components/Education";
import Features from "../components/Features";
import Cta from "../components/Cta";
import Counter from "../components/Counter";
import Video from "../components/Video";
import Screenshot from "../components/Screenshot";
import Blog from "../components/Blog";
import Footer from "../components/Footer";
import ChatBox from "../components/ChatBox";
import Header from "../components/Header";
import Testimonial from "../components/Testimonial";
import Accordion from "../components/Accordion";
import Subscribe from "../components/Subscribe";
import Client from "../components/Client";
import Pricing from "../components/Pricing";
import Scrolltop from "../components/Scrolltop";
import ReactGA from "react-ga";
import { useState } from "react";
import store from "../store";

// UA-196224044-1
const queryString = require("query-string");

const Home = () => {
	const [url, updateUrl] = React.useState("https://triviarat.com/sign-up");

	//const [utmSource, setUtmSource] = useState("");
	const setUtmSource = (utmSource) => {
		store.setItem("utm_source", utmSource);
		const kw = extractKeyWord(utmSource);

		if (kw) {
			store.setItem("kw", kw);
			removeQueryParameters();
		}
	};

	function extractKeyWord(str) {
		const match = str.match(/kw=([^&]*)/);
		return match ? match[1] : null;
	}

	function removeQueryParameters() {
		const url = window.location.href;
		const baseUrl = url.split("?")[0];
		window.history.pushState({}, document.title, baseUrl);
	}

	useEffect(() => {
		ReactGA.initialize("UA-196224044-1");
		ReactGA.pageview(window.location.pathname + window.location.search);

		var parsed = queryString.parse(window.location.search);
		if (typeof parsed.utm_source !== "undefined") {
			var address = "/sign-up?utm_source=" + parsed.utm_source;
			updateUrl(address);
			setUtmSource(parsed.utm_source);
		}
	}, []);

	return (
		<>
			<Header />
			<main>
				<ChatBox />
				<Hero url={url} />
				<About url={url} />
				<Features />

				<Testimonial />
				<Video />
				<Cta url={url} />
				<Accordion />
				<Pricing url={url} />
				<Education url={url} />
				<Footer />
			</main>
			<Scrolltop />
		</>
	);
};

export default Home;
