import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./whitepages/Home";

function App() {
	return (
		<Router basename="/">
			<Switch>
				<Route
					exact
					path={`${process.env.PUBLIC_URL + "/"}`}
					component={Home}
				/>
			</Switch>
		</Router>
	);
}

export default App;
