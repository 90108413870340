// copy.js
import store from "../store"; // Import the store module

const copyData = [
	{
		keywords: ["team", "collaboration"],
		reviews: [
			{
				text: `TriviaRat has been a great way to keep our team connected during the pandemic. We've been using it for our weekly team meetings and it's been a great way to keep things fun and engaging.`,
				name: "The Pylon Inc.",
				type: "Marketing Agency",
				url_title: "thepylon-marketing",
				url: "#",
			},
			{
				text: `We used the Team Building category and discovered that all these years NO ONE gets any value out of our friday meetings. Even management.  We have now replaced those meetings with trivia :).`,
				name: "ElectoSense",
				type: "Voting Platform SaaS",
				url_title: "electosense.com",
				url: "https://electosense.com/",
			},
			{
				text: `Every company should use triviarat at least once for their team building questions. The answers are submitted anonymously so people really let loose and you learn alot about your coworkers.`,
				name: "Webfly Software Inc.",
				type: "Software Development",
				url_title: "webfly.io",
				url: "https://webfly.io/",
			},
		],
	},
	{
		keywords: ["friends", "groups"],
		reviews: [
			{
				text: `We used the 'trivia with friends' category and I somehow discovered that my best friend was in love me with me LOL :).`,
				name: "Amy M.",
				type: "Hosted",
				url_title: "(NA)",
				url: "#",
			},
			{
				text: `If you are just looking for something simple to handle automatically keeping track of score/marking for large groups, use TriviaRat. No bells and whistles, just good ol' fashion trivia.`,
				name: "Jeffrey B.",
				type: "Player",
				url_title: "(NA)",
				url: "#",
			},
		],
	},
	{
		keywords: ["hosting", "app", "software"],
		reviews: [
			{
				text: `We cannot not say enough good things about Trivia Rat. They've made hosting a breeze and increased brand awareness and acquiring new social media followers tenfold. Trivia Rat kept Hub of the Hammer Event Planning alive during the pandemic and we're thrilled to keep partnering with them in whatever comes next`,
				name: "Hub of the Hammer",
				type: "Event Planning",
				url: "www.hubofthehammer.com",
				url_title: "hubofthehammer.com",
			},
			{
				text: `I was actually going to shutdown our trivia wednesdays because it was becoming too much work, using TriviaRat has taken all the headache out of it and now I'm actually looking forward to them again!`,
				name: "Sapphire Entertainment Inc.",
				type: "Trivia & Comedy",
				url: "https://sapphireentertainment.com/",
				url_title: "SapphireEntertainment.com/",
			},

			// {
			// 	text: `TriviaRat gave us something to look forward to during COVID Lockdown!
			// 	It was so easy to setup and run, in my second Quiz Night I had over 60 people joining in for a night of fun, smiles and questions!
			// 	All the participants found it so easy to use too!`,
			// 	name: "Adam | Lockdown Trivia",
			// 	type: "Trivia Hosting",
			// },
			{
				text: `Trivia Rat has been essential to me over this past year! It helped me continue to provide fun trivia games virtually. It's easy to use, has a variety of settings for personalisation, and the creators have always been helpful`,
				name: "BeccaHosts",
				instagram: "@BeccaHosts",
				facebook: "http://www.beccahosts.com/BeccaHosts/",
				url_title: "BeccaHosts.com",
				url: "beccahosts.com",
				type: "Trivia Entertainment",
			},
			{
				text: "TriviaRat has kept us alive during lockdown! We'll continue to use it onsite as it does everything we need, and its very user friendly.",
				name: "Sunset Trivia",
				type: "Trivia Company",
				url_title: "sunsettrivia.com",
				url: "https://sunsettrivia.com/",
			},
			{
				text: "I started with TR when I moved to zoom, and i've continued to use it at the restaurants because i hate buying a new set of pens every week :)",
				name: "CrazyTalk Trivia",
				type: "Trivia Host",
				url_title: "instagram.com/crazytalk_trivia",
				url: "https://instagram.com/crazytalk_trivia",
			},
			{
				text: "We've used paper and pen for years, but TriviaRat has made it so much easier to run our trivia nights. We'll never go back!",
				name: "Quizical Trivia",
				type: "Hosting Company",
				url_title: "quizzical-trivia.net",
				url: "https://webfly.io/quizzical",
			},
		],
	},
];

function getReviews() {
	// Retrieve the keyword from the store
	const kw = store.getItem("kw");
	const keywords = kw ? kw.split(" ") : []; // Assuming keywords are space-separated

	// Find the first object where any of its keywords is in the keyword set
	const match = copyData.find((item) =>
		item.keywords.some((keyword) => keywords.includes(keyword))
	);

	// Return the found object or the default object (assumed to be the last one)
	const record = match || copyData[copyData.length - 1];
	return record.reviews;
}

export default getReviews;
